<!--
   Created by Terence on 2022/9/8 - 11:16
-->
<template>
  <div :class="['page', 'oc-profile-list-page']">
    <!--    <browser-cover />-->
    <!--    <download-banner @onInvokeApp="onOpenProfilePage" />-->
    <hover-guide-btn @onInvokeApp="onOpenProfilePage" />
    <!--    <img-->
    <!--      class="top-cover"-->
    <!--      :src="detailData.cover + '!mfit_w750_h750_jpg'"-->
    <!--      alt=""-->
    <!--    />-->
    <div class="content">
      <div class="content-nav">
        <van-tabs
          v-model:active="currentActiveTab"
          @click-tab="onClickTab"
          shrink
        >
          <van-tab
            :title="item.stageName"
            v-for="(item, index) in tabs"
            :key="index"
          />
        </van-tabs>
      </div>
      <product-list
        :currentArtType="tabs[currentActiveTab]"
        :creator="detailData.creator"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, shallowRef, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Image as VanImage, Tab as VanTab, Tabs as VanTabs } from "vant";
import { DuButton } from "@frontend/dumpling";
import NftApi from "@/apis";
import DownloadBanner from "@/components/DownloadBanner";
import HoverGuideBtn from "@/components/HoverGuideBtn";
import ArrowRight from "@/components/ArrowRight";
import { getQuery } from "@/utils/url";
import ProductList from "./components/product-list";
import { invokeAppByYYB } from "@/utils/mob";

const router = useRouter();

const DEFAULT_TABS = [
  {
    stageName: "全部",
    value: null,
  },
];

let detailData = ref({});
const currentTab = ref("token");
const currentActiveTab = ref(0);
const tabs = ref(DEFAULT_TABS);
// const currentTab = ref("product");

const onClickTab = (itemData) => {
  console.log("item!!!", tabs.value[itemData.name]);
};

const onOpenProfilePage = () => {
  const { characterId } = getQuery();
  invokeAppByYYB({
    targetPath: `oc`,
    params: {
      id: characterId,
    },
  });
};

const onShowBidList = () => {
  const { subjectId } = getQuery();
  console.log("detailData", detailData);
  router.push(
    `/subject-bid-list?subjectId=${subjectId}&title=${detailData.value.name}`
  );
};

const onClickMenuItem = (item) => {
  currentTab.value = item.key;
};

const initTokenTypeList = async () => {
  const { characterId } = getQuery();
  const res = await NftApi.getTokenTypeList(characterId);

  // const filteredTabs = DEFAULT_TABS.filter((tab) =>
  //   res?.data?.list.includes(tab.value)
  // );
  const filteredTabs = res?.data?.list?.map((item) => {
    return {
      stageName: item.name,
      value: item.type,
    };
  });
  filteredTabs.unshift(DEFAULT_TABS[0]);
  tabs.value = filteredTabs;
};

const initData = async () => {
  const { characterId } = getQuery();
  // const { data } = await NftApi.getSubjectList({ subjectId, limit: 20, offset: 0 });

  const { data } = await NftApi.getOcProfile(characterId);

  console.log("data==!!", data);
  // detailData = reactive(data.token || {})
  detailData.value = data;

  // initJump();
  document.title = `${detailData.value.name}`;

  // console.log("detailData===", detailData);
};

initData();
initTokenTypeList();
</script>

<style lang="scss" scoped>
img {
  vertical-align: bottom;
}
.page {
  position: relative;
  min-height: 100vh;
  padding-bottom: 72rpx;
  background-color: white;
  .content {
    &-nav {
      overflow-x: scroll;
    }
    .relation-wrp {
      padding: 0 30rpx;
      .rel-slots {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 16rpx;
        &-prefix {
          display: inline-block;
          flex: 0 0 84rpx;
          margin-right: 20rpx;
          color: #000;
          font-size: 28rpx;
          font-style: normal;
          font-weight: 400;
          line-height: 44rpx; /* 157.143% */
        }
        &-items {
          flex: 1;
          color: #000;
          font-size: 28rpx;
          font-style: normal;
          font-weight: 500;
          line-height: 44rpx; /* 157.143% */
        }
      }
      .rel-slogan {
        margin-bottom: 16rpx;
        color: rgba(0, 0, 0, 0.5);
        font-size: 24rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 36rpx; /* 150% */
      }
    }

    .section {
      padding: 0 30rpx;
      &-title {
        display: flex;
        align-items: center;
        text-align: left;
        font-weight: 800;
        margin-top: 48rpx;
        font-size: 48rpx;
        line-height: 64rpx;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-subtitle {
          margin-left: 16rpx;
          font-weight: 400;
          font-size: 28rpx;
          line-height: 44rpx;
          color: rgba(0, 0, 0, 0.5);
        }
        &-more {
          color: rgba(0, 0, 0, 0.5);
          /* 💙 Medium/H6(Label) */
          font-size: 24rpx;
          font-style: normal;
          font-weight: 500;
          line-height: 36rpx; /* 150% */
        }
      }
    }
  }
}

.oc-profile-list-page {
  --van-nav-bar-background: transparent;
  --van-nav-bar-icon-color: #000000;
  --van-nav-bar-arrow-size: 40rpx;
  --van-nav-bar-title-font-size: 36rpx;

  --van-tabs-bottom-bar-width: 32rpx;
  --van-tabs-bottom-bar-height: 8rpx;
  --van-tabs-bottom-bar-color: #7247dc;

  margin-bottom: 12rpx;
  //padding: 0 20rpx;
  .van-nav-bar__left {
    padding: unset;
  }
  //.van-tabs {
  //  position: sticky;
  //  top: 0;left: 0;
  //}
  .van-tabs__nav {
    background: transparent;
  }
  .van-tabs__line {
    bottom: 40rpx;
    border-radius: 1rpx;
  }
  .van-tab {
    //flex: unset;
    margin-right: 40rpx;
    font-size: 28rpx;
    line-height: 44rpx;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.64);
  }
  .van-tab--active {
    font-weight: 500;
    color: #000000;
  }
}

.otext2 {
  display: -webkit-box;
  overflow: hidden;

  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
