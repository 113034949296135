<template>
  <div class="birthdayborder">
    <div class="birthdayborder-top" />
    <div class="birthdayborder-left" />
    <div class="birthdayborder-right" />
    <div class="birthdayborder-bottom" />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.birthdayborder {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  &-top {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 20rpx;
    background-color: #ffa28d;
  }
  &-left {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    width: 20rpx;
    height: 100%;
    background-color: #ffa28d;
  }
  &-right {
    position: absolute;
    z-index: 100;
    right: 0;
    top: 0;
    width: 20rpx;
    height: 100%;
    background-color: #ffa28d;
  }
  &-bottom {
    position: absolute;
    z-index: 100;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20rpx;
    background-color: #ffa28d;
  }
}
</style>
