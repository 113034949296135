<template>
  <div class="card">
    <img class="card-icon" :src="relationConfig.icon" alt="" />
    <div class="card-content" :style="{ background: relationConfig.bgc }">
      <div class="title otext2">
        {{ data.title }}
      </div>
      <div class="list">
        <div
          class="list-item"
          v-for="(item, index) in data.characters"
          :key="index"
          @click="goToProfile(item)"
        >
          <div class="list-item-img">
            <img :src="item.cover + '!mfit_w480_h480_jpg'" alt="" />
          </div>
          <div class="list-item-name otext">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, onMounted, computed } from "vue";
import { OC_RELATION_TYPES } from "@/constant/oc";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});

const relationConfig = computed(() => {
  const { type } = props.data;
  switch (type) {
    case OC_RELATION_TYPES.CP:
      return {
        bgc: "linear-gradient(225deg, #fe9797 0%, #ffd5ae 100%)",
        icon: "https://cdn.qiandaoapp.com/interior/images/4b0c0c8fd5d940b7c38a8dbee372c46e.png",
      };
    case OC_RELATION_TYPES.RELATIVE:
      return {
        bgc: "linear-gradient(225deg, #7CC4EC 0%, #7CECD1 100%)",
        icon: "https://cdn.qiandaoapp.com/interior/images/a5dba16a3e9fc211a3bcf106a9281496.png",
      };
    case OC_RELATION_TYPES.FRIEND:
      return {
        bgc: "linear-gradient(225deg, #A5C168 0%, #D5D868 100%)",
        icon: "https://cdn.qiandaoapp.com/interior/images/35f96ce00a3b3d8a611912b0d42e3041.png",
      };
    default: {
      return {
        bgc: "linear-gradient(225deg, #fe9797 0%, #ffd5ae 100%)",
        icon: "https://cdn.qiandaoapp.com/interior/images/4b0c0c8fd5d940b7c38a8dbee372c46e.png",
      };
    }
  }
});

const goToProfile = (item) => {
  router.push(`/oc-profile?characterId=${item.id}`);
};
</script>

<style lang="scss" scoped>
.card {
  width: 0; // 为了让flex布局生效
  //flex: 0 0 calc(33% - 16rpx);
  position: relative;
  //padding-top: 16rpx;
  //padding-right: 8rpx;
  &-icon {
    position: absolute;
    right: 8rpx;
    top: 0;
    width: 80rpx;
    height: 68rpx;
  }
  &-content {
    padding: 16rpx 8rpx;
    border-radius: 16rpx;
    //background: linear-gradient(225deg, #fe9797 0%, #ffd5ae 100%);
    .title {
      padding-right: 60rpx;
      color: #fff;
      font-size: 24rpx;
      font-style: normal;
      font-weight: 500;
      line-height: 36rpx; /* 150% */
    }
    .list {
      width: 100%;
      &-item {
        display: flex;
        align-items: center;
        margin-top: 16rpx;
        &-img {
          flex: 0 0 64rpx;
          border-radius: 8rpx;
          width: 64rpx;
          height: 64rpx;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &-name {
          flex: 1;
          margin-left: 8rpx;
          font-size: 24rpx;
          font-weight: 500;
          line-height: 36rpx;
          color: #fff;
        }
      }
    }
  }
}
</style>
