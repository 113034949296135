<!--
   Created by Terence on 2022/9/13 - 16:10
-->
<template>
  <div class="content">
    <div class="content-title">
      <span
        :class="`content-title--item ${
          defaultTab === 'owned' && 'content-title--item--actived'
        }`"
        @click="handleChangeTab('owned')"
        >拥有的</span
      >
      <span
        :class="`content-title--item ${
          defaultTab === 'created' && 'content-title--item--actived'
        }`"
        @click="handleChangeTab('created')"
        >创作的</span
      >
      <!--      <span-->
      <!--        :class="`content-title&#45;&#45;item ${-->
      <!--          defaultTab === 'oc' && 'content-title&#45;&#45;item&#45;&#45;actived'-->
      <!--        }`"-->
      <!--        @click="handleChangeTab('oc')"-->
      <!--        >OC档案</span-->
      <!--      >-->
    </div>
    <div class="content-list">
      <template v-if="defaultTab !== 'oc'">
        <div v-for="(item, index) in listData" :key="index">
          <div :class="`content-list--item`" @click="handleClickItem">
            <img
              class="content-list--item--img"
              :src="item.imageUrl + '!mfit_w480_h480_jpg'"
              alt=""
            />
            <div class="content-list--item--name">{{ item.name }}</div>
          </div>
        </div>
        <div class="empty" v-if="!listData.length && !isLoading">
          {{ emptyText }}
        </div>
      </template>
      <template v-else>
        <oc-profiles-list
          :listData="ocProfiles"
          @onInvokeApp="handleClickItem"
        />
        <div class="empty" v-if="!ocProfiles.length && !isLoading">
          {{ emptyText }}
        </div>
      </template>

      <div v-if="listData.length" class="more">
        <div class="more-left" />
        去APP查看更多作品
        <div class="more-right" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, computed } from "vue";
import OcProfilesList from "./OcProfilesList.vue";
import { getQuery } from "@/utils/url";
import NftApi from "@/apis";

let defaultTab = ref("owned");
// let defaultTab = ref("oc"); // TODO remove
let listData = ref([]);
let ocProfiles = ref({});
let isLoading = ref(false);

const props = defineProps({});

const emits = defineEmits(["onInvokeApp"]);

// const initJump = () => {
//   setTimeout(() => {
//     emits("onInitJump");
//   }, 0);
// };

const emptyText = computed(() => {
  const prefix = "暂无";
  let suffix = "作品";
  let content = "";

  if (defaultTab.value === "owned") {
    content = "拥有的";
  } else if (defaultTab.value === "created") {
    content = "创作的";
  } else if (defaultTab.value === "oc") {
    content = "OC";
    suffix = "档案";
  }
  return prefix + content + suffix;
});

const handleChangeTab = (name) => {
  defaultTab.value = name;
};

const handleClickItem = () => {
  emits("onInvokeApp");
};

// 86336294443024385
const getCreatedTokens = async () => {
  isLoading.value = true;
  const { userId } = getQuery();
  const { data } = await NftApi.getCreatedTokens(userId);
  isLoading.value = false;
  listData.value = data?.items || [];
  // initJump();
  console.log("getCreatedTokens:data===", data);
};

const getOwnedTokens = async () => {
  isLoading.value = true;
  const { userId } = getQuery();
  const { data } = await NftApi.getOwnedTokens(userId);
  isLoading.value = false;
  listData.value = data?.items || [];
  // initJump();
  console.log("getOwnedTokens:data===", data);
};

const getOcTokens = async () => {
  isLoading.value = true;
  const { userId } = getQuery();
  // const { data } = await NftApi.getOwnedTokens(userId);
  isLoading.value = false;
  // listData.value = data?.items || [];

  ocProfiles.value = [
    {
      cover:
        "https://cdn.qiandaoapp.com/interior/images/773174113f87bcdacbe7cc0e11f1b214.png!lfit_w750_jpg",
      name: "档案名称1",
      count: 11,
      price: 666,
      username: "ytc666",
      avatar:
        "https://cdn.qiandaoapp.com/interior/images/773174113f87bcdacbe7cc0e11f1b214.png!lfit_w750_jpg",
      tokens: [
        {
          cover:
            "https://cdn.qiandaoapp.com/interior/images/773174113f87bcdacbe7cc0e11f1b214.png!lfit_w750_jpg",
        },
        {
          cover:
            "https://cdn.qiandaoapp.com/interior/images/773174113f87bcdacbe7cc0e11f1b214.png!lfit_w750_jpg",
        },
        {
          cover:
            "https://cdn.qiandaoapp.com/interior/images/773174113f87bcdacbe7cc0e11f1b214.png!lfit_w750_jpg",
        },
      ],
    },
    {
      cover:
        "https://cdn.qiandaoapp.com/interior/images/773174113f87bcdacbe7cc0e11f1b214.png!lfit_w750_jpg",
      name: "档案名称2",
      count: 12,
      price: 888,
      tokens: [
        {
          cover:
            "https://cdn.qiandaoapp.com/interior/images/773174113f87bcdacbe7cc0e11f1b214.png!lfit_w750_jpg",
        },
        {
          cover:
            "https://cdn.qiandaoapp.com/interior/images/773174113f87bcdacbe7cc0e11f1b214.png!lfit_w750_jpg",
        },
        {
          cover:
            "https://cdn.qiandaoapp.com/interior/images/773174113f87bcdacbe7cc0e11f1b214.png!lfit_w750_jpg",
        },
      ],
    },
  ];
};

const getList = async () => {
  if (defaultTab.value === "owned") {
    getOwnedTokens();
  } else if (defaultTab.value === "created") {
    getCreatedTokens();
  } else if (defaultTab.value === "oc") {
    getOcTokens();
  }
};

watch(
  () => defaultTab.value,
  (val) => {
    getList();
  },
  {
    immediate: true,
  }
);
</script>

<style lang="scss" scoped>
.content {
  margin-top: 70rpx;
  padding: 0 30rpx;
  &-title {
    padding-bottom: 24rpx;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &--item {
      position: relative;
      padding-right: 40rpx;
      font-size: 40rpx;
      line-height: 52rpx;
      color: rgba(0, 0, 0, 0.3);
    }
    &--item--actived {
      font-weight: 600;
      color: #000000;
      &::after {
        content: "";
        position: absolute;
        width: 136rpx;
        height: 8rpx;
        left: 0;
        top: 70rpx;
        background: linear-gradient(
          90deg,
          #7247dc 0%,
          rgba(43, 26, 192, 0) 100%
        );
      }
    }
  }
  &-list {
    width: 100%;
    padding: 36rpx 0 224rpx 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &--item {
      margin-bottom: 18rpx;
      width: 336rpx;
      height: 396rpx;
      border-radius: 24rpx;
      background-color: #fff;
      overflow: hidden;
      &--img {
        width: 336rpx;
        height: 336rpx;
        height: auto;
      }
      &--name {
        margin-left: 8rpx;
        font-size: 24rpx;
        font-weight: 500;
        line-height: 36rpx;
        color: #000000;
      }
    }
  }
  .more {
    margin: 44rpx 0 20rpx 0;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 28rpx;
    line-height: 44rpx;
    color: rgba(0, 0, 0, 0.3);
    &-left {
      margin-right: 16rpx;
      width: 160rpx;
      height: 1px;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.1) 100%
      );
    }
    &-right {
      margin-left: 16rpx;
      width: 160rpx;
      height: 1px;
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.1) 100%
      );
    }
  }
  .empty {
    display: flex;
    justify-content: center;
    align-items: end;
    width: 100%;
    min-height: 200rpx;
    font-size: 28rpx;
    line-height: 44rpx;
    color: rgba(0, 0, 0, 0.3);
  }
}
</style>
