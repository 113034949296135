<!--
   Created by Terence on 2022/9/13 - 11:05
-->
<template>
  <div @click="handleClick" :class="`guidebtn`" :style="extStyle">
    <img
      src="https://cdn.qiandaoapp.com/ued/805019971499fa4c5309285ff358ab06.png"
      alt=""
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  extStyle: {
    type: Object,
  },
  onClickBanner: {
    type: Function,
    default: () => null,
  },
});

const emits = defineEmits(["onInvokeApp"]);

const handleClick = () => {
  emits("onInvokeApp");
};
</script>

<style lang="scss" scoped>
.guidebtn {
  position: fixed;
  z-index: 1000;
  left: 50%;
  bottom: 84rpx;
  transform: translateX(-50%);
  width: 354rpx;
  height: auto;
  img {
    width: 100%;
  }
}
</style>
