<template>
  <div class="list">
    <template v-if="list.length">
      <!--    <template v-if="false">-->
      <template v-for="(item, index) in list" :key="index">
        <relation-card :data="item" />
      </template>
    </template>
    <div class="empty" v-else>
      <div class="empty-title">还没有认识其他角色</div>
      <div class="empty-btn">
        <DuButton
          v-if="isMe"
          type="info"
          size="small"
          extStyle="width: 280rpx"
          @click="handleJumpToFriendGroup"
          >去交友圈认识新角色</DuButton
        >
      </div>
    </div>
  </div>
  <!--  https://cdn.qiandaoapp.com/interior/images/4b0c0c8fd5d940b7c38a8dbee372c46e.png-->
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from "vue";
import { DuButton } from "@frontend/dumpling";
import RelationCard from "@/components/RelationCard/index.vue";
import { storeToRefs } from "pinia";
import { OC_RELATION_TYPES } from "@/constant/oc";
import { useUsersStore } from "@/store/user";
import { getQuery } from "@/utils/url";
import { invokeAppByYYB } from "@/utils/mob";

const userStore = useUsersStore();

const { userInfo } = storeToRefs(userStore);

const props = defineProps({
  creator: {
    type: Object,
  },
  list: {
    type: Array,
  },
});

// const listData = [
//   {
//     type: OC_RELATION_TYPES.CP,
//     title:
//       "大发大大大范德萨啊大幅度发达大幅度发达打发顺丰达大发大大大大发大大大范德萨啊大幅度发达大幅度发达打发顺丰达大发大大大",
//     list: [
//       {
//         name: 123112321312312231231123213123122312311232131231223,
//         img: "https://cdn.qiandaoapp.com/interior/images/b3d77f69b048481898cb7c2d32c694d1.png!mfit_w750_h750_jpg",
//       },
//       {
//         name: 2222,
//         img: "https://cdn.qiandaoapp.com/interior/images/b3d77f69b048481898cb7c2d32c694d1.png!mfit_w750_h750_jpg",
//       },
//     ],
//   },
//   {
//     title: "111",
//     type: OC_RELATION_TYPES.RELATIVE,
//     list: [
//       {
//         name: 123123,
//         img: "https://cdn.qiandaoapp.com/interior/images/b3d77f69b048481898cb7c2d32c694d1.png!mfit_w750_h750_jpg",
//       },
//       {
//         name: 2222,
//         img: "https://cdn.qiandaoapp.com/interior/images/b3d77f69b048481898cb7c2d32c694d1.png!mfit_w750_h750_jpg",
//       },
//     ],
//   },
//   {
//     title: "222",
//     type: OC_RELATION_TYPES.FRIEND,
//     list: [
//       {
//         name: 123123,
//         img: "https://cdn.qiandaoapp.com/interior/images/b3d77f69b048481898cb7c2d32c694d1.png!mfit_w750_h750_jpg",
//       },
//       {
//         name: 2222,
//         img: "https://cdn.qiandaoapp.com/interior/images/b3d77f69b048481898cb7c2d32c694d1.png!mfit_w750_h750_jpg",
//       },
//     ],
//   },
//   // {
//   //   title: "222",
//   //   type: OC_RELATION_TYPES.FRIEND,
//   //   list: [
//   //     {
//   //       name: 123123,
//   //       img: 'https://cdn.qiandaoapp.com/interior/images/b3d77f69b048481898cb7c2d32c694d1.png!mfit_w750_h750_jpg'
//   //     },
//   //     {
//   //       name: 2222,
//   //       img: 'https://cdn.qiandaoapp.com/interior/images/b3d77f69b048481898cb7c2d32c694d1.png!mfit_w750_h750_jpg'
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: "222",
//   //   type: OC_RELATION_TYPES.FRIEND,
//   //   list: [
//   //     {
//   //       name: 123123,
//   //       img: 'https://cdn.qiandaoapp.com/interior/images/b3d77f69b048481898cb7c2d32c694d1.png!mfit_w750_h750_jpg'
//   //     },
//   //     {
//   //       name: 2222,
//   //       img: 'https://cdn.qiandaoapp.com/interior/images/b3d77f69b048481898cb7c2d32c694d1.png!mfit_w750_h750_jpg'
//   //     },
//   //   ],
//   // },
// ];

const isMe = computed(() => {
  return userInfo.value.userId === props.creator?.userId;
  // return '499382826827779408' === props.creator?.userId;
});

const handleJumpToFriendGroup = () => {
  invokeAppByYYB({
    targetPath: `oc/wall`,
    params: {},
  });
};
</script>

<style lang="scss" scoped>
.list {
  min-width: 690rpx;
  display: flex;
  flex-wrap: wrap;
  //background-color: red;
  & > .card {
    //flex: 0 0 33%;
    flex: 1;
    padding-right: 16rpx;
    padding-top: 16rpx;
    margin-bottom: 16rpx;
  }
  & > .card:nth-child(3n) {
    //color: red;
    margin-right: 0;
  }
}
.empty {
  margin-top: 32rpx;
  display: flex;
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  &-title {
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    font-size: 28rpx;
    font-style: normal;
    font-weight: 400;
    line-height: 44rpx; /* 157.143% */
  }
  &-btn {
    margin-top: 24rpx;
    width: 100%;
    text-align: center;
  }
}
</style>
