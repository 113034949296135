<template>
  <!--  TODO -->
  <!--  <div v-if="false">-->
  <div v-if="listData.length">
    <van-list
      v-model:loading="loading"
      :finished="finished"
      :finished-text="maxCount ? '' : '没有更多了'"
      :immediate-check="false"
      @load="loadMoreFc"
    >
      <div class="wrp">
        <div class="list">
          <template
            class="list-item"
            v-for="(item, index) in listData.slice(0, maxCount)"
            :key="index"
          >
            <item-card
              :item="item"
              :extStyle="{
                marginRight: 'unset',
                justifyContent: 'space-between',
              }"
            />
          </template>
        </div>
      </div>
    </van-list>
  </div>
  <div class="empty" v-else>
    <div class="empty-title">暂无角色作品</div>
    <div class="empty-btn">
      <DuButton
        v-if="isMe"
        type="info"
        size="small"
        icon="plus-circle"
        extStyle="width: 222rpx"
        @click="handleJumpToFriendGroup"
        >去添加一个</DuButton
      >
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, computed } from "vue";
import { useRoute } from "vue-router";
import { List as VanList } from "vant";
import { DuButton } from "@frontend/dumpling";
import ItemCard from "./item-card.vue";

import { getQuery } from "@/utils/url";
import NftApi from "@/apis";
import { REQUEST_OK } from "@/constant/network";
import { storeToRefs } from "pinia";
import { useUsersStore } from "@/store/user";
import { invokeAppByYYB } from "@/utils/mob";

const route = useRoute();
const userStore = useUsersStore();

const { userInfo } = storeToRefs(userStore);

const props = defineProps({
  currentArtType: {
    type: Object,
    required: true,
  },
  creator: {
    type: Object,
  },
  maxCount: {
    type: Number,
  },
});

const emits = defineEmits(["onClickItem"]);

const isMe = computed(() => {
  return userInfo.value.userId === props.creator?.userId; // TODO use this
  // return "499382826827779408" === props.creator?.userId;
});

const listData = ref([]);
const leftData = ref([]);
const rightData = ref([]);
const loading = ref(false);
const loadingFailed = ref(false);
const finished = ref(false);
const leftHeight = ref(0);
const rightHeight = ref(0);
const currentItemHeight = ref(0);

const handleJumpToFriendGroup = () => {
  const { characterId } = getQuery();
  invokeAppByYYB({
    targetPath: `token/create`,
    params: {
      ocId: characterId,
    },
  });
};

const loadMoreFc = () => {
  console.log("loadingFailed.value", loadingFailed.value);
  if (loadingFailed.value) return;
  initData(); // TODO release
};

const initData = async ({ isReset } = { isReset: false }) => {
  const { characterId } = getQuery();

  loading.value = true;

  const tempParams = props.currentArtType.value
    ? { artType: props.currentArtType.value }
    : {};

  // const { data } = await NftApi.getSubjectList({
  const { code, data } = await NftApi.getOcRelatedPost({
    ...tempParams,
    characterId,
    limit: 10,
    offset: isReset ? 0 : listData.value.length,
  });

  loading.value = false;

  if (code !== REQUEST_OK) {
    loadingFailed.value = true;
    return;
  }
  // const dpr = window.devicePixelRatio

  // if (!data?.data?.length) {
  //   finished.value = true;
  //   return;
  // }

  const itemWidth = 348;
  const defaultWidth = 348;
  const defaultHeight = 464;
  const windowWidth = document.body.clientWidth;
  const scaleRatio = windowWidth / 750;
  // detailData = reactive(data.token || {})
  const tempData = data.list.map((item) => {
    let tempImgInfo = {
      url: item.imageUrl,
    };

    if (item?.covers?.[0]?.url) {
      tempImgInfo = item.covers[0];
    } else if (item?.watermaskImages?.[0]?.url) {
      tempImgInfo = item.watermaskImages[0];
    }

    // 如果不是竖图 是长图
    // if (+tempImgInfo.width > +tempImgInfo.height) {
    //   tempImgInfo.calcWidth = scaleRatio * 344;
    //   tempImgInfo.calcHeight = scaleRatio * 344;
    // } else {
    //   if (tempImgInfo.width) {
    //     const imgRatio = itemWidth / tempImgInfo.width;
    //     tempImgInfo.calcWidth = scaleRatio * itemWidth;
    //     tempImgInfo.calcHeight = scaleRatio * (imgRatio * tempImgInfo.height);
    //   } else {
    //     tempImgInfo.calcWidth = scaleRatio * defaultWidth;
    //     tempImgInfo.calcHeight = scaleRatio * defaultHeight;
    //   }
    // }

    tempImgInfo.calcWidth = scaleRatio * 344;
    tempImgInfo.calcHeight = scaleRatio * 344;

    item.coverImgInfo = tempImgInfo;
    return item;
  });

  if (isReset) {
    listData.value = tempData;
  } else {
    listData.value = listData.value.concat(tempData);
  }

  const hasMore = data.hasMore;
  if (hasMore) {
    finished.value = false;
  } else {
    finished.value = true;
  }
};

watch(
  () => props.currentArtType,
  () => {
    initData({ isReset: true });
  },
  {
    immediate: false,
  }
);

watch(
  () => route.query,
  (newParams, oldParams) => {
    if (newParams.characterId !== oldParams.characterId) {
      console.log("characterId has changed to:!!!!", newParams.characterId);
      initData({ isReset: true });
    }
  }
);

initData();
</script>

<style lang="scss" scoped>
.wrp {
  margin-top: 30rpx;
  padding: 0 20rpx;
  display: flex;
  flex-direction: row;
}
.list {
  width: 100%;
  //column-count: 4;
  //column-gap: 0;
  display: flex;
  //flex-direction: column;
  //flex-flow: column wrap;
  //align-content: center;
  flex-wrap: wrap;
  justify-content: space-between;
  //justify-content: space-between;
  //background-color: skyblue;
  &-item {
    //flex: 0 0 348rpx;
    flex: 0 0 200rpx;
    //background-color: skyblue;
    //border: 1rpx solid red;
    &--img {
      //width: 174rpx;
      //height: auto;
    }
  }
  &-item:nth-child(2n) {
    margin-right: 0;
    //background-color: red;
  }
  &-item--active {
    position: relative;
    font-weight: bold;
    font-size: 48rpx;
    line-height: 64rpx;
    color: #000000;
    .bg-line {
      position: absolute;
      width: 80%;
      height: 24rpx;
      background: linear-gradient(
        90.09deg,
        rgba(114, 71, 220, 0.5) -4.2%,
        rgba(114, 71, 220, 0) 104.04%
      );
      transform: translateX(15%) rotate(-20deg);
    }
  }
}
.empty {
  margin-top: 32rpx;
  display: flex;
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  &-title {
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    font-size: 28rpx;
    font-style: normal;
    font-weight: 400;
    line-height: 44rpx; /* 157.143% */
  }
  &-btn {
    margin-top: 24rpx;
    width: 100%;
    text-align: center;
  }
}
</style>
