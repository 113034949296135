<!--
   Created by Terence on 2022/9/8 - 11:16
-->
<template>
  <div :class="['page', 'oc-profile-page']">
    <download-banner @onInvokeApp="onOpenProfilePage" />
    <hover-guide-btn @onInvokeApp="onOpenProfilePage" />
    <div class="page-main">
      <birthday-border v-if="isShowBirthDayUI" />
      <van-image
        fit="cover"
        class="top-cover"
        :src="detailData.cover + '!mfit_w750_h750_jpg'"
        alt=""
      />
      <div class="info">
        <div class="info-title">{{ detailData.name }}</div>
        <div
          class="info-desc otext2"
          v-html="detailData.description"
          style="white-space: pre-line"
        />
        <birthday-banner
          :isShowBirthDayUI="isShowBirthDayUI"
          :birthdayDate="birthdayDate"
        />
        <div class="info-summery">
          <div class="left">
            <div class="left-item">
              <div class="left-item-value">
                {{ +detailData.relatedTokenCount || "暂无" }}
              </div>
              <div class="left-item-label">相关作品</div>
            </div>
            <div class="left-item" v-if="+detailData.estimatePrice">
              <div class="left-item-value">¥{{ detailData.estimatePrice }}</div>
              <div class="left-item-label">总花费</div>
            </div>
            <div class="left-item" v-if="+detailData.totalLikeCount">
              <div class="left-item-value">
                {{ detailData.totalLikeCount }}
              </div>
              <div class="left-item-label">获赞总数</div>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="section">
          <div class="section-title">
            角色关系
            <div class="section-title-more" @click="handleShowMoreRelation">
              查看完整关系 <ArrowRight />
            </div>
          </div>
        </div>

        <div class="relation-wrp">
          <div
            class="rel-slots"
            v-if="detailData?.lookForRoles?.length || detailData.socialBio"
          >
            <span class="rel-slots-prefix">征集中</span>
            <span class="rel-slots-items">
              <span
                class="rel-slots-items-item"
                v-for="(item, index) in detailData.lookForRoles"
                :key="index"
                >{{ item }}</span
              >
            </span>
          </div>
          <div class="rel-slogan">{{ detailData.socialBio }}</div>
          <relation-list :list="relationList" :creator="detailData.creator" />
        </div>

        <div class="section">
          <div class="section-title">
            角色作品
            <div class="section-title-more" @click="goToListPage">
              查看完整作品 <ArrowRight />
            </div>
          </div>
        </div>
        <product-list
          :currentArtType="tabs[currentActiveTab]"
          :creator="detailData.creator"
          :maxCount="4"
        />

        <div class="section">
          <div class="section-title">评论</div>
        </div>
        <comment-list
          :style="{ marginTop: '3.2rem' }"
          token-id=""
          :listData="commentListData"
          :loading="commentLoading"
          :finished="commentFinished"
          collection-data=""
          @handleChangeLoadingStatus="handleChangeLoadingStatus"
          @loadMoreFc="initCommentList"
          @onClickItem="onOpenProfilePage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, shallowRef, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Image as VanImage, Tab as VanTab, Tabs as VanTabs } from "vant";
import { time } from "@frontend/echo-utils";
import dayjs from "dayjs";
import { DuButton } from "@frontend/dumpling";
import NftApi from "@/apis";
import DownloadBanner from "@/components/DownloadBanner";
import HoverGuideBtn from "@/components/HoverGuideBtn";
import ArrowRight from "@/components/ArrowRight";
import RelationList from "./components/RelationList/index.vue";
import BirthdayBorder from "./components/BirthdayBorder/index.vue";
import BirthdayBanner from "./components/BirthdayBanner/index.vue";
import { getQuery } from "@/utils/url";
import ProductList from "../OcProfileList/components/product-list";
import CommentList from "@/components/CommentList/index.vue";
import { invokeAppByYYB } from "@/utils/mob";
import { OC_RELATION_TYPES } from "@/constant/oc";
import { timeDiff } from "@/utils/time";

const route = useRoute();
const router = useRouter();

const DEFAULT_TABS = [
  {
    stageName: "全部",
    value: null,
  },
];

let detailData = ref({});
const currentTab = ref("token");
const currentActiveTab = ref(0);
const relationList = ref([]);
const commentListData = ref([]);
const commentLoading = ref(false);
const commentFinished = ref(false);
const isShowBirthDayUI = ref(false);
const birthdayDate = ref("");
const tabs = ref(DEFAULT_TABS);
// const currentTab = ref("product");

const onClickTab = (itemData) => {
  console.log("item!!!", tabs.value[itemData.name]);
};

const handleShowMoreRelation = () => {
  const { characterId } = getQuery();
  invokeAppByYYB({
    targetPath: `oc/relationship`,
    params: {
      ocId: characterId,
    },
  });
};

const goToListPage = () => {
  const { characterId } = getQuery();
  router.push(`/oc-profile-list?characterId=${characterId}`);
};

const onOpenProfilePage = () => {
  const { characterId } = getQuery();
  invokeAppByYYB({
    targetPath: `oc`,
    params: {
      id: characterId,
    },
  });
};

const onShowBidList = () => {
  const { subjectId } = getQuery();
  console.log("detailData", detailData);
  router.push(
    `/subject-bid-list?subjectId=${subjectId}&title=${detailData.value.name}`
  );
};

const onClickMenuItem = (item) => {
  currentTab.value = item.key;
};

const initRelationList = async () => {
  const { characterId } = getQuery();
  const res = await NftApi.getOcProfileRelations({
    characterId,
    isBrief: true,
    offset: 0,
    limit: 3,
  });
  const tempList = res?.data?.list;
  tempList.forEach((item, index) => {
    if (index === 0) {
      item.type = OC_RELATION_TYPES.CP;
    }
    if (index === 1) {
      item.type = OC_RELATION_TYPES.RELATIVE;
    }
    if (index === 2) {
      item.type = OC_RELATION_TYPES.FRIEND;
    }
  });

  relationList.value = tempList;
};

const initTokenTypeList = async () => {
  const { characterId } = getQuery();
  const res = await NftApi.getTokenTypeList(characterId);

  // const filteredTabs = DEFAULT_TABS.filter((tab) =>
  //   res?.data?.list.includes(tab.value)
  // );
  const filteredTabs = res?.data?.list?.map((item) => {
    return {
      stageName: item.name,
      value: item.value,
    };
  });
  filteredTabs.unshift(DEFAULT_TABS[0]);
  tabs.value = filteredTabs;
};

const handleChangeLoadingStatus = (val) => {
  commentLoading.value = val;
};

const initCommentList = async ({ isReset } = { isReset: false }) => {
  const { characterId } = getQuery();
  const tempParams = {
    characterId,
    limit: 20,
  };
  if (commentListData.value.length) {
    tempParams.cursor =
      commentListData.value[commentListData.value.length - 1].id;
  }

  commentLoading.value = true;
  const res = await NftApi.getFormatedCommentList(tempParams);
  commentLoading.value = false;

  const tempData = res?.data?.list;

  tempData.forEach((item) => {
    let timeString = timeDiff(+new Date(item.createdAt));
    item.timeString = timeString;
  });

  commentFinished.value = res.data?.more;

  if (isReset) {
    commentListData.value = tempData;
  } else {
    commentListData.value = commentListData.value.concat(tempData);
  }
};

const initData = async () => {
  const { characterId } = getQuery();
  // const { data } = await NftApi.getSubjectList({ subjectId, limit: 20, offset: 0 });

  const { data } = await NftApi.getOcProfile(characterId);

  console.log("data==!!", data);
  // detailData = reactive(data.token || {})
  detailData.value = data;

  // const isBirthDay = dayjs().isSame(+data.anniversary * 1000, "day");
  const isBirthDay =
    dayjs(+data.anniversary * 1000).format("MM-DD") ===
    dayjs(+new Date()).format("MM-DD");
  console.log("isBirthDay===", isBirthDay);

  birthdayDate.value = +data.anniversary
    ? dayjs(+new Date(+data.anniversary * 1000)).format("YYYY.MM.DD")
    : "暂无";
  if (isBirthDay) {
    isShowBirthDayUI.value = true;
  }

  document.title = `${detailData.value.name}`;
};

const initAllData = () => {
  initData();
  initTokenTypeList();
  initCommentList();
  initRelationList();
};

watch(
  () => route.query,
  (newParams, oldParams) => {
    if (newParams.characterId !== oldParams.characterId) {
      console.log("characterId has changed to:", newParams.characterId);
      initAllData();
    }
  }
);

initAllData();
</script>

<style lang="scss" scoped>
img {
  vertical-align: bottom;
}
.page {
  position: relative;
  min-height: 100vh;
  background-color: white;
  &-main {
    position: relative;
    padding-bottom: 72rpx;
  }
  .top-cover {
    width: 750rpx;
    height: 750rpx;
    object-fit: cover;
    vertical-align: bottom;
  }
  .info {
    padding-top: 48rpx;
    padding-left: 20rpx;
    background: linear-gradient(
      179.51deg,
      rgba(126, 103, 192, 0.1) 0.42%,
      white 100%
    );
    &-title {
      font-weight: bold;
      font-size: 64rpx;
      line-height: 80rpx;
      color: #000000;
    }
    &-desc {
      margin-top: 24rpx;
      font-weight: 400;
      font-size: 32rpx;
      line-height: 48rpx;
      color: rgba(0, 0, 0, 0.5);
    }
    &-summery {
      width: 100%;
      margin-top: 24rpx;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-item {
          margin-left: 64rpx;
          &-label {
            color: rgba(0, 0, 0, 0.5);
            font-size: 20rpx;
            font-style: normal;
            font-weight: 400;
            line-height: 22rpx; /* 110% */
          }
          &-value {
            color: #000;
            font-family: "Roboto";
            font-size: 28rpx;
            font-weight: 700;
            line-height: 44rpx; /* 157.143% */
          }
        }
        &-item:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  }
  .list-btn {
    border: 2rpx solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.5);
  }
  .content {
    &-nav {
      margin-top: 30rpx;
      //padding: 0 30rpx;
      overflow-x: scroll;
    }
    .relation-wrp {
      padding: 0 20rpx;
      .rel-slots {
        display: flex;
        width: 100%;
        //align-items: center;
        justify-content: center;
        margin-top: 16rpx;
        &-prefix {
          display: inline-block;
          flex: 0 0 90rpx;
          margin-right: 20rpx;
          color: #000;
          font-size: 28rpx;
          font-style: normal;
          font-weight: 400;
          line-height: 44rpx; /* 157.143% */
        }
        &-items {
          flex: 1;
          color: #000;
          font-size: 28rpx;
          font-style: normal;
          font-weight: 500;
          line-height: 44rpx; /* 157.143% */
          &-item {
            margin-right: 20rpx;
          }
        }
      }
      .rel-slogan {
        margin-bottom: 16rpx;
        color: rgba(0, 0, 0, 0.5);
        font-size: 24rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 36rpx; /* 150% */
      }
    }

    .section {
      padding: 0 20rpx;
      &-title {
        display: flex;
        align-items: center;
        text-align: left;
        font-weight: 800;
        margin-top: 48rpx;
        font-size: 48rpx;
        line-height: 64rpx;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-subtitle {
          margin-left: 16rpx;
          font-weight: 400;
          font-size: 28rpx;
          line-height: 44rpx;
          color: rgba(0, 0, 0, 0.5);
        }
        &-more {
          color: rgba(0, 0, 0, 0.5);
          /* 💙 Medium/H6(Label) */
          font-size: 24rpx;
          font-style: normal;
          font-weight: 500;
          line-height: 36rpx; /* 150% */
        }
      }
    }
  }
}

.oc-profile-page {
  --van-nav-bar-background: transparent;
  --van-nav-bar-icon-color: #000000;
  --van-nav-bar-arrow-size: 40rpx;
  --van-nav-bar-title-font-size: 36rpx;

  --van-tabs-bottom-bar-width: 32rpx;
  --van-tabs-bottom-bar-height: 8rpx;
  --van-tabs-bottom-bar-color: #7247dc;

  margin-bottom: 12rpx;
  //padding: 0 20rpx;
  .van-nav-bar__left {
    padding: unset;
  }
  //.van-tabs {
  //  position: sticky;
  //  top: 0;left: 0;
  //}
  .van-tabs__nav {
    background: transparent;
  }
  .van-tabs__line {
    bottom: 40rpx;
    border-radius: 1rpx;
  }
  .van-tab {
    //flex: unset;
    margin-right: 40rpx;
    font-size: 28rpx;
    line-height: 44rpx;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.64);
  }
  .van-tab--active {
    font-weight: 500;
    color: #000000;
  }
}

.otext2 {
  display: -webkit-box;
  overflow: hidden;

  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
