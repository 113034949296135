<template>
  <div class="container">
    <DuButton
      v-if="isMe"
      type="info"
      size="small"
      icon="plus-circle"
      full
      @click="handleJumpToOC"
      >创建新的OC档案</DuButton
    >
    <div :class="`list ${isMe ? 'topgap' : ''}`">
      <div class="list-item" v-for="(item, index) in listData" :key="index">
        <div class="content-wrp">
          <div class="list-item-left">
            <img :src="item.cover" alt="" />
          </div>
          <div class="list-item-right">
            <div class="name">{{ item.name }}</div>
            <div class="info">
              <div class="info-count" v-if="item.count">
                <span class="label">相关作品</span>
                <span class="value">{{ item.count }}</span>
              </div>

              <div class="info-price" v-if="item.price">
                <span class="label">总花费</span>
                <span class="value">¥{{ item.price }}</span>
              </div>
            </div>
            <div class="tokens">
              <img
                class="tokens-item"
                v-for="(single, singleIndex) in item.tokens"
                :src="single.cover"
                :key="singleIndex"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="userinfo">
          <div class="gapline" />
          <div class="userinfo-content">
            <img class="avatar" :src="item.avatar" alt="" />
            <div class="username">{{ item.username }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, computed } from "vue";
import { DuButton } from "@frontend/dumpling";
import { storeToRefs } from "pinia";
import { useUsersStore } from "@/store/user";
import { getQuery } from "@/utils/url";
const userStore = useUsersStore();

const { userInfo } = storeToRefs(userStore);

const props = defineProps({
  listData: {
    type: Array,
    default: () => [],
  },
});

const emits = defineEmits(["onInvokeApp"]);

const isMe = computed(() => {
  const { userId } = getQuery();
  return userInfo.value.userId === userId;
});

const handleJumpToOC = () => {
  emits("onInvokeApp");
};
</script>

<style lang="scss" scoped>
.topgap {
  margin-top: 24rpx;
}
.container {
  width: 100%;
}
.list {
  width: 100%;
  .content-wrp {
    width: 100%;
    display: flex;
  }
  &-item {
    padding: 16rpx;
    margin-bottom: 24rpx;
    display: flex;
    flex-direction: column;
    border-radius: 24rpx;
    width: 100%;
    background-color: #fff;
    &-left {
      flex: 0 0 228rpx;
      width: 228rpx;
      height: 228rpx;
      border-radius: 16rpx;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        //object-fit: cover;
      }
    }
    &-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 8rpx;
      padding-top: 12rpx;
      .name {
        color: #000;
        font-size: 28rpx;
        font-weight: 600;
        line-height: 28rpx; /* 100% */
      }
      .info {
        display: flex;
        align-items: center;
        //margin-top: 12rpx;
        &-price {
          margin-left: 16rpx;
        }
        .label {
          font-size: 20rpx;
          font-weight: 400;
          //line-height: 22rpx; /* 110% */
        }
        .value {
          margin-left: 4rpx;
          color: rgba(0, 0, 0, 0.5);
          font-family: "Roboto";
          font-size: 20rpx;
          font-style: normal;
          font-weight: 700;
          //line-height: 22rpx; /* 110% */
        }
      }
      .tokens {
        //margin-top: 12rpx;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-item {
          width: 135rpx;
          height: 135rpx;
          object-fit: cover;
          border-radius: 16rpx;
          overflow: hidden;
        }
      }
    }
  }
  .userinfo {
    margin-top: 17rpx;
    .gapline {
      border-top: 2rpx solid rgba(32, 36, 38, 0.06);
    }
    &-content {
      margin-top: 16rpx;
      display: flex;
      align-items: center;
      .avatar {
        min-width: 32rpx;
        width: 32rpx;
        height: 32rpx;
        border-radius: 100%;
        overflow: hidden;
      }
      .username {
        margin-left: 8rpx;
        overflow: hidden;
        color: rgba(0, 0, 0, 0.5);
        text-overflow: ellipsis;
        whitespace: nowrap;
        font-size: 24rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 36rpx; /* 150% */
      }
    }
  }
}
</style>
