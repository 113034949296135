<!--
   Created by Terence on 2022/9/8 - 11:16
-->
<template>
  <div class="page">
    <!--    <browser-cover />-->
    <div
      class="head-bg"
      :style="
        detailData?.bannerImageUrl && {
          backgroundImage: `url(${detailData.bannerImageUrl}})`,
        }
      "
    />
    <img class="avatar" :src="avatarUrl" alt="" />
    <div class="user">
      <div class="user-name">{{ detailData.displayName }}</div>
      <div class="user-hash mobLink::profile">
        {{ detailData.address }}&nbsp;
        <img
          class="copy"
          src="https://cdn.qiandaoapp.com/ued/fe124dab05fbf2f3eca4701125d15959.png"
          alt=""
        />
      </div>
    </div>
    <profile-content @onInvokeApp="onInvokeApp" />
    <div class="follow-btn">
      <main-button @onInvokeApp="onInvokeApp" text="关注" size="large" />
    </div>
  </div>
</template>
<!--https://cdn.qiandaoapp.com/ued/c36f913044d4cfe9ffe3865dd5bdb732.png-->
<script setup>
import { ref, reactive, shallowRef, onMounted, computed } from "vue";
import NftApi from "@/apis";
import BrowserCover from "@/components/BrowserCover";
import MainButton from "@/components/MainButton";
import ProfileContent from "./components/ProfileContent";
import ArrowRight from "@/components/ArrowRight";
import { getQuery } from "@/utils/url";
import { invokeAppByYYB } from "@/utils/mob";

let detailData = ref({});

const avatarUrl = computed(() => {
  const avatarUrl = detailData?.value?.avatarUrl;
  if (avatarUrl) {
    return `${avatarUrl}!mfit_w480_h480_jpg`;
  } else {
    return `https://cdn.qiandaoapp.com/admins/31abdad7a18f0fa821f0297df843d34d.jpeg!mfit_w480_h480_jpg`;
  }
});

const getCreatedTokens = async () => {
  const { userId } = getQuery();
  const { data } = await NftApi.getUserProfile(userId);
  detailData.value = data?.account || {};
  document.title = `临界上的创作者：${detailData.value.displayName}`;
  console.log("detailDeata!!!!", detailData);
};

const onInvokeApp = () => {
  const { userId } = getQuery();
  invokeAppByYYB({
    targetPath: "profile",
    params: { userId },
  });
};

// const onInitJump = () => {
//   const { userId } = getQuery();
//   prepareJump({
//     atrr: ".mobLink::profile",
//     targetPath: "/nftPersonalProfile",
//     params: { userId },
//   });
// };

getCreatedTokens();

onMounted(() => {
  // onInitJump();
});
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  min-height: 100vh;
  background-color: #f8f8f8;
  .head-bg {
    width: 100%;
    height: 562rpx;
    left: 0;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("https://cdn.qiandaoapp.com/ued/7f4cd064bd2584dcedb94adb855382f0.png!mfit_w540_h540_jpg");
  }
  .avatar {
    position: absolute;
    top: 390rpx;
    left: 50%;
    transform: translateX(-50%);
    width: 240rpx;
    height: 240rpx;
    border-radius: 100%;
    overflow: hidden;
  }
  .user {
    margin-top: 84rpx;
    &-name {
      font-weight: 800;
      font-size: 40rpx;
      line-height: 52rpx;
      text-align: center;
      color: #000000;
    }
    &-hash {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16rpx;
      font-size: 24rpx;
      text-align: center;
      color: #000000;
      opacity: 0.5;
      .copy {
        width: 28rpx;
        height: 28rpx;
      }
    }
  }
  .follow-btn {
    position: fixed;
    z-index: 1;
    bottom: 84rpx;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
