<!--
   Created by Terence on 2022/9/8 - 17:47
-->
<template>
  <div class="container">
    <div
      v-if="isWxBrowser || isQQBrowser || isWeiboBrowser"
      class="mask"
      @click="handleClickMask"
    >
      <img
        class="mask-guide"
        src="https://cdn.qiandaoapp.com/admins/137127a2f28c2accdd5285fbc0cc398e.png"
        alt=""
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits } from "vue";
const isWxBrowser = ref();
const isQQBrowser = ref();
const isWeiboBrowser = ref();

const emits = defineEmits(["onAutoDownload"]);

const isWxBrowserFn = () => {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
};

const isQQBrowserFn = () => {
  return (
    window.navigator.userAgent.indexOf("MQQBrowser") > -1 ||
    navigator.userAgent.indexOf("QQTheme") > -1
  );
};

const isWeiboBrowserFn = () => {
  return window.navigator.userAgent.toLowerCase().match(/WeiBo/i) == "weibo";
};

const handleClickMask = (e) => {
  e.stopPropagation();
};

isWxBrowser.value = isWxBrowserFn();
isQQBrowser.value = isQQBrowserFn();
isWeiboBrowser.value = isWeiboBrowserFn();

watch(
  () => [isWxBrowser.value, isQQBrowser.value, isWeiboBrowser.value],
  (values) => {
    if (values.some((item) => !!item)) {
      emits("onAutoDownload");
    }
  },
  {
    immediate: true,
  }
);
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  .mask {
    min-height: 100vh;
    position: absolute;
    z-index: 10;
    background-color: white;
    //background-color: skyblue;
    &-guide {
      width: 100%;
      height: auto;
      pointer-events: none;
    }
  }
}
</style>
