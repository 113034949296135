<template>
  <div class="item" :style="extStyle" @click="jumpToTokenDetail(item)">
    <div class="cover-wrp">
      <van-image
        fit="cover"
        class="item-img"
        :width="item.coverImgInfo.calcWidth + 2"
        :height="item.coverImgInfo.calcHeight"
        :src="item.coverImgInfo.url + '!mfit_w480_h480_jpg'"
        alt=""
      />
      <div class="img-above-info" v-if="type === 'token' && item.estimateFee">
        <div :class="`img-above-info--item`">
          购入价格：¥{{ item.estimateFee }}
        </div>
      </div>
    </div>
    <div v-if="type === 'token'">
      <div class="item-title">{{ item.name }}</div>
      <div class="item-info">
        <div class="item-info--user user">
          <van-image
            fit="cover"
            class="user-avatar"
            :src="item.owner.avatarUrl + '!mfit_w480_h480_jpg'"
            alt=""
          />
          <div class="user-name">
            {{ item.owner.displayName }}
          </div>
        </div>
        <div class="like">
          <img
            class="like-icon"
            src="https://cdn.qiandaoapp.com/ued/7fee56bfe82a32028f78fe0d61b2fec7.png!mfit_w480_h480_jpg"
            alt=""
          />
          <div class="like-count">
            {{ item.likeCount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { Image as VanImage } from "vant";
import { useRouter } from "vue-router";
import dayjs from "dayjs";
import { getCountdownTime, getWeekDate } from "@/utils/time";

const router = useRouter();

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  extStyle: {
    type: Object,
  },
  type: {
    type: String,
    default: "token", // 作品item
  },
});

const auctionDescInfo = computed(() => {
  // 未开始: auctionInfo.status === 'NO_TRADE'
  // 竞拍中, 无人出价:  auctionInfo.bidCount为0
  // 竞拍中, 有人出价: auctionInfo.bidCount > 0
  // 竞拍成功: auctionInfo.status == "ENDED"
  const unit = "¥";
  let info = {
    title: "起拍价",
    price: unit,
    pricePrefix: "",
    theme: "gray",
  };

  const { auctionInfo } = props.item.tradeInfo;
  const { status, currentPrice, bidCount } = auctionInfo || {};
  if (status === "NOT_START") {
    info = {
      title: "起拍价",
      price: unit + currentPrice,
      pricePrefix: "当前",
      theme: "gray",
    };
  } else if (bidCount === 0) {
    info = {
      title: "起拍价",
      price: unit + currentPrice,
      pricePrefix: "当前",
      theme: "gray",
    };
  } else if (bidCount > 0 && status !== "ENDED") {
    info = {
      title: "最高价",
      price: unit + currentPrice,
      theme: "dark",
    };
  } else if (status == "ENDED") {
    info = {
      title: "成交价",
      price: unit + currentPrice,
      theme: "gray",
    };
  }

  return info;
});

const auctionCountdownStrArr = computed(() => {
  // 未开始: auctionInfo.status === 'NO_TRADE'
  // 竞拍中, 无人出价:  auctionInfo.bidCount为0
  // 竞拍中, 有人出价: auctionInfo.bidCount > 0
  // 竞拍成功: auctionInfo.status == "ENDED"
  // ，
  const result = [];

  const { auctionInfo } = props.item.tradeInfo;
  const { status, currentPrice, bidCount, finishTime } = auctionInfo || {};
  // const { status, currentPrice, bidCount, finishTime } = { status: 'NOT_START', finishTime: 1674632667000,};
  const finishTimeNumber = +finishTime;

  const timeYYDD = dayjs(finishTimeNumber).format("MM/DD");
  const timeWeekDate = getWeekDate(finishTimeNumber);
  const timeHourMinute = dayjs(finishTimeNumber).format("HH:mm");

  if (status === "NOT_START") {
    result.push({ text: "未开始，", size: "normal" });
    result.push({ text: timeYYDD, size: "big" });
    result.push({ text: timeWeekDate, size: "normal" });
    result.push({ text: timeHourMinute, size: "big" });
    result.push({ text: "截拍", size: "normal" });
  } else if (bidCount === 0) {
    result.push({ text: timeYYDD, size: "big" });
    result.push({ text: timeWeekDate, size: "normal" });
    result.push({ text: timeHourMinute, size: "big" });
    result.push({ text: "截拍", size: "normal" });
  } else if (bidCount > 0 && status !== "ENDED") {
    result.push({ text: timeYYDD, size: "big" });
    result.push({ text: timeWeekDate, size: "normal" });
    result.push({ text: timeHourMinute, size: "big" });
    result.push({ text: "截拍", size: "normal" });
  }

  return result;
});

const jumpToTokenDetail = (data) => {
  if (props.type === "auction" || props.type === "product") {
    router.push(`/token-detail?tokenId=${data.tokenId}`);
  } else {
    router.push(`/token-detail?tokenId=${data.id}`);
  }
};
</script>

<style lang="scss" scoped>
.item {
  position: relative;
  padding-bottom: 16rpx;
  width: 348rpx;
  display: flex;
  flex-direction: column;
  //align-items: center;
  margin-right: 14rpx;
  margin-bottom: 14rpx;
  font-weight: 400;
  font-size: 40rpx;
  line-height: 52rpx;
  color: rgba(0, 0, 0, 0.3);
  border-radius: 24rpx;
  overflow: hidden;

  box-shadow: 0 8rpx 40rpx rgba(0, 0, 0, 0.06);
  background-color: #fff;
  &-title {
    padding: 12rpx 8rpx 0 8rpx;
    font-weight: bold;
    font-size: 28rpx;
    line-height: 44rpx;
    color: #000000;

    width: 332rpx;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &-info {
    padding: 0 8rpx;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8rpx;
    height: 48rpx;
    &--user,
    .user {
      display: flex;
      align-items: center;
      &-avatar {
        width: 32rpx;
        height: 32rpx;
        border-radius: 100%;
        overflow: hidden;
        //border: 0.5px solid rgba(0, 0, 0, 0.1);
        border: 2rpx solid rgba(32, 36, 38, 0.06);
      }
      &-name {
        margin-left: 8rpx;
        width: 220rpx;
        font-weight: 400;
        font-size: 24rpx;
        line-height: 36rpx;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .like {
      display: flex;
      align-items: center;
      &-icon {
        width: 32rpx;
        height: 32rpx;
      }
      &-count {
        margin-left: 5rpx;
        font-weight: 900;
        font-size: 32rpx;
        line-height: 48rpx;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.cover-wrp {
  position: relative;
  .img-above-info {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 44rpx;
    color: white;
    font-size: 20rpx;
    background: rgba(0, 0, 0, 0.7);
    &--item {
      margin-right: 4rpx;
      font-weight: 800;
      font-size: 20rpx;
    }
    &--item:last-child {
      margin-right: 0;
    }
    &--item--big {
      font-weight: 900;
      font-size: 24rpx;
    }
  }
}
.auction-wrp,
.product-wrp {
  .user-name {
    white-space: unset !important;
    width: 150rpx !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .auction {
    display: flex;
    flex-direction: column;
    padding: 4rpx 20rpx;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 56rpx;
    font-size: 20rpx;
    line-height: 22rpx;
    color: #000000;
    &-title {
    }
    &-price {
      margin-top: 4rpx;
      display: flex;
      align-items: center;
      &--prefix {
      }
      &--value {
        //font-family: 'Roboto';
        font-weight: 900;
        font-size: 20rpx;
        line-height: 22rpx;
        color: #000000;
      }
    }
  }
  .auction-dark {
    color: white;
    background-color: #000000;
    .auction-price {
      color: white;
      &--value {
        color: white;
      }
    }
  }

  .product-price {
    display: flex;
    align-items: center;
    &--stock {
      font-weight: 400;
      font-size: 20rpx;
      line-height: 22rpx;
      color: rgba(0, 0, 0, 0.5);
    }
    &--value {
      //font-family: 'Roboto';
      margin-left: 8rpx;
      font-weight: 900;
      font-size: 28rpx;
      line-height: 44rpx;
      color: #000000;
    }
  }
}
</style>
